import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import Header from "../components/header";
import Footer from "../components/footer";
import About from "../components/about";

import ContactUs from "../components/contactUs";

const Strategy = ({ location }) => {
  return (
    <Fragment>
      <Helmet>
        <title>National SEO Organic search ranking #1</title>
        <meta
          name="description"
          content="Our National SEO puts your business in Organic search ranking, #1 for national searches in your category. For free analysis of your national SEO presence call...."
        />
        <link href="https://seocannabis.agency/delivery-cannabis-seo" rel="canonical" />
      </Helmet>
      <main>
        <Header pathname={location.pathname} />
        <ToastContainer />
        <About
          titlel1="Delivery & Ecommerce"
          // caption="Ranking #1 in Organic search"
          titleWidth="520px"
          description="Are you the leading cannabis delivery service in your market? Or the top cannabis ecommerce site? If you are the #1 brand available to potential customers, you should be ranking #1 too. Start a conversation with us today and we’ll show you how we can send your brand to the top of the search list."
          desktopImg={"nationalseoimg"}
          mobileImg="flag.png"
          page="national"
        />
        <ContactUs
          head1="Make the Best First Impression"
          // head3="Optimize Your Digital Footprint"
          des1="Want to gain credibility with potential customers? Rank #1 for your brand keyword when someone searches for it. Your google results are the first impression your business makes to potential customers. We can help you reach the top spot for your cannabis delivery or ecommerce service."
          // des2="Controlling your search presence is more important than ever. Schedule a call with one of our specialists today to have us identify your company’s weak points and put together a strategy for improved organic presence going forward."
          // head2="Be 2022 Ready"
          image="home.png"
        />
        <Footer isTopborder={true} />
      </main>
    </Fragment>
  );
};

export default Strategy;
